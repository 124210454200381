import React from "react"
import "../styles/_press.scss"
import { Row, Col, Container } from "react-bootstrap"

const FeatureTopBar = () => {
  return (
    <div className="feature-top-bar py-1">
      <Container className="outer">
        <Row className="align-items-center justify-content-center">
          <Col>
            <div className="text-center">
              Featured in{" "}
              <a
                className="f-16"
                href="https://www.entrepreneur.com/starting-a-business/spaceit-is-transforming-how-real-estate-brokers-do-business/453547"
                target="_blank"
              >
                Entrepreneur.com
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FeatureTopBar
