/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Header from "./header"

const Layout = ({ children, _pageInfo, hero, showFeature }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          showFeature={showFeature}
        />
        {hero && (
          <Container fluid className="px-0">
            {hero}
          </Container>
        )}

        <main className="main" id="content">
          <Container data-id="maincon" fluid className="px-0 main">
            <Row noGutters>
              <Col>{children}</Col>
            </Row>
          </Container>
        </main>

        {/* Footer */}
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <footer>
                <ul className="footer-menu">
                  <li>
                    &copy; {new Date().getFullYear()}
                    {` `}
                    SpaceIt&trade;
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/p/press-releases">Press</a>
                  </li>
                </ul>
              </footer>
            </Col>
          </Row>
        </Container>
        {/* /Footer */}
      </>
    )}
  />
)

export default Layout
