import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
// import logo from "../images/logo@2x.png";
import logo from "../images/spaceit-logo.svg"
import { Row, Col, Container } from "react-bootstrap"
import FeatureTopBar from "./feature-top-bar"

const Header = ({ siteTitle, showFeature }) => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      setScrolled(false)
    }
  }, [])

  return (
    <header className={scrolled ? "scrolled" : null}>
      {showFeature && <FeatureTopBar />}
      <Container fluid="xl" className="px-4 px-md-5">
        <Row className="inner align-items-center justify-content-center">
          <Col className="" xs={{ span: 6 }}>
            <a href="/">
              <img className="logo" alt="SpaceIt Logo" src={logo} />
            </a>
          </Col>
          <Col className="text-right" xs={{ span: 6 }}>
            <a className="" href="https://appv2.spaceit.com/">
              Login
            </a>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
